import request from "@/utils/request";

//发布公告
export function addNotice(data) {
  return request({
    url: "/notice/announcement/addNotice",
    method: "post",
    data: data,
  });
}

//公告字典
export function dictList(params) {
  return request({
    url: "/notice/announcement/dictList",
    method: "get",
    params: params,
  });
}
//下线公告
export function downNotice(params) {
  return request({
    url: `/notice/announcement/downNotice/${params}`,
    method: "get",
    // params: params,
  });
}
//编辑公告
export function editNotice(data) {
  return request({
    url: "/notice/announcement/editNotice",
    method: "post",
    data: data,
  });
}
//根据字典码获取字典列表
export function getDictListByCode(params) {
  return request({
    url: `/notice/announcement/getDictListByCode`,
    method: "get",
    params: params,
  });
}
//公告列表
export function getNoticeList(data) {
  return request({
    url: `/notice/announcement/getNoticeList`,
    method: "post",
    data,
  });
}
//公告详情
export function noticeInfo(params) {
  return request({
    url: `/notice/announcement/info/${params}`,
    method: "get",
    // params: params,
  });
}
//获取消息列表
export function msgGetList(params) {
  return request({
    url: `/message/mgr/getList`,
    method: "get",
    params: params,
  });
}
//设置消息为已读
export function readMessage(params) {
  return request({
    url: `/message/mgr/readMessage/${params}`,
    method: "PUT",
  });
}
//获取公告模块下所有的字典列表
export function getAllDictListByNotice(params) {
  return request({
    url: `/notice/announcement/getAllDictListByNotice`,
    method: "get",
    params: params,
  });
}

//web

export function websocketApi(params) {
  return request({
    url: `/api/websocket/${params}`,
    method: "get",
    // params: params,
  });
}

/* 获取规章制度 */

export function getListByType(params) {
  return request({
    url: `/notice/announcement/getListByType`,
    method: "get",
    params: params,
  });
}
